import React, { useRef, useState } from 'react';
import useIntersectionObserver from '../../lib/hook/useIntersectionObserver';

function Content({children, className, html}) {
    const ref = useRef();
    const [isVisible, setIsVisible] = useState(false);
  
    useIntersectionObserver(ref, () => setIsVisible(true), { threshold: 0.1 });
  
    return (
      <>
        {
          html ? <div ref={ref} className={`${className} ${isVisible ? 'fade-in' : ''}`} dangerouslySetInnerHTML={{ __html:html }} />
                    : <div ref={ref} className={`${className} ${isVisible ? 'fade-in' : ''}`}>
                      {children}
                    </div>
        }
      </>
    );
}

export default Content;