import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from './template/Content';

function Tokenomics({modalFn}) {
    const { t } = useTranslation();

    const openModal = () => {
        modalFn(true);
    }
    return (
        <div className='max-w-[1920px] mx-auto flex flex-col px-[1.5rem] py-[2.5rem] gap-[1.25rem]
                        sm:py-[4.5rem] sm:gap-[2rem]
                        md:py-[10.5rem] gnb_padding md:gap-[4rem] token-bg relative  z-[1]' id='tokenomics'>
            <img src="/assets/background/body/tokenomics.png" className='tokenomics' alt='' />
            <div className='flex flex-col gap-[0.5rem]
                            sm:gap-[1rem]
                            md:gap-[2rem]'>
                <Content className='content-bottom text-color04 mobile-title-32
                                tablet-title-64
                                desktop-title-68'>
                    {t('tokenomics.text1')}
                </Content>
                <Content className='content-bottom text-color02 mobile-body-12-regular
                                tablet-body-24-regular
                                desktop-body-18-regular'>
                    {t('tokenomics.text2')}
                </Content>
            </div>
            <Content className='content'><img src='/assets/tokenomics/content.png' alt='' className='cursor-pointer' onClick={openModal} /></Content>
            <div className='flex flex-col gap-[0.5rem]
                            sm:gap-[1rem]
                            md:gap-[2rem]'>
                <Content className='content-bottom text-color01 mobile-title-20
                                tablet-title-40
                                desktop-title-36'>
                    {t('tokenomics.text3')}
                </Content>
                <Content className='content-bottom text-color02 mobile-body-12-regular
                                tablet-body-24-regular
                                desktop-body-18-regular'  html={t('tokenomics.text4')} >
                </Content>
            </div>
            {/* card */}
            <div className='flex flex-col gap-[1rem]
                            sm:gap-[1.5rem]
                            md:flex-row md:justify-between'>
                {/* widget */}
                <Content className='content-bottom flex flex-row justify-center tokenomics_bg_img gap-[1rem]  p-[1.5rem]
                                sm:px-[5.75rem] sm:py-[4.5rem]
                                md:px-[1rem] md:py-[3.5rem] md:flex-col md:items-center md:gap-[2.75rem] md:flex-1'>
                    <div className='w-[3.5rem] h-[3.5rem] tokenomics_img_01
                                    sm:w-[9.0625rem] sm:h-[9.0625rem]
                                    md:w-[7.5rem] md:h-[7.5rem]' />
                    <div className='flex-1 flex flex-col gap-[0.5rem]
                                    sm:gap-[1rem] 
                                    md:gap-[1.5rem] md:items-center md:text-center'>
                        <div className='text-color05 mobile-body-14-semibold
                                        tablet-body-32-semibold
                                        desktop-body-24-bold'>
                            {t('tokenomics.text5')}
                        </div>
                        <div className='text-color02 mobile-body-12-regular
                                        tablet-body-24-regular
                                        desktop-body-16-regular text-left'>
                            {t('tokenomics.text6')}
                        </div>
                    </div>
                </Content>
                <Content className='content-bottom flex flex-row justify-center tokenomics_bg_img gap-[1rem]  p-[1.5rem]
                                sm:px-[5.75rem] sm:py-[4.5rem]
                                md:px-[1rem] md:py-[3.5rem] md:flex-col md:items-center md:gap-[2.75rem]  md:flex-1'>
                    <div className='w-[3.5rem] h-[3.5rem] tokenomics_img_02
                                    sm:w-[7.4375rem] sm:h-[7.4375rem]
                                    md:w-[7.5rem] md:h-[7.5rem]' />
                    <div className='flex-1 flex flex-col gap-[0.5rem]
                                    sm:gap-[1rem] 
                                    md:gap-[1.5rem] md:items-center md:text-center'>
                        <div className='text-color05 mobile-body-14-semibold
                                        tablet-body-32-semibold
                                        desktop-body-24-bold'>
                        {t('tokenomics.text7')}
                        </div>
                        <div className='text-color02 mobile-body-12-regular
                                        tablet-body-24-regular
                                        desktop-body-16-regular text-left'>
                        {t('tokenomics.text8')}
                        </div>
                    </div>
                </Content>
                <Content className='content-bottom flex flex-row justify-center tokenomics_bg_img gap-[1rem]  p-[1.5rem]
                                sm:px-[5.75rem] sm:py-[4.5rem]
                                md:px-[1rem] md:py-[3.5rem] md:flex-col md:items-center md:gap-[2.75rem]  md:flex-1'>
                    <div className='w-[3.5rem] h-[3.5rem] tokenomics_img_03
                                    sm:w-[7.4375rem] sm:h-[7.4375rem]
                                    md:w-[7.5rem] md:h-[7.5rem]' />
                    <div className='flex-1 flex flex-col gap-[0.5rem]
                                    sm:gap-[1rem] 
                                    md:gap-[1.5rem] md:items-center md:text-center'>
                        <div className='text-color05 mobile-body-14-semibold
                                        tablet-body-32-semibold
                                        desktop-body-24-bold '>
                        {t('tokenomics.text9')}
                        </div>
                        <div className='text-color02 mobile-body-12-regular
                                        tablet-body-24-regular
                                        desktop-body-16-regular text-left'>
                        {t('tokenomics.text10')}
                        </div>
                    </div>
                </Content>
                <Content className='content-bottom flex flex-row justify-center tokenomics_bg_img gap-[1rem]  p-[1.5rem]
                                sm:px-[5.75rem] sm:py-[4.5rem]
                                md:px-[1rem] md:py-[3.5rem] md:flex-col md:items-center md:gap-[2.75rem]  md:flex-1'>
                    <div className='w-[3.5rem] h-[3.5rem] tokenomics_img_04
                                    sm:w-[7.4375rem] sm:h-[7.4375rem]
                                    md:w-[7.5rem] md:h-[7.5rem]' />
                    <div className='flex-1 flex flex-col gap-[0.5rem]
                                    sm:gap-[1rem] 
                                    md:gap-[1.5rem] md:items-center md:text-center'>
                        <div className='text-color05 mobile-body-14-semibold
                                        tablet-body-32-semibold
                                        desktop-body-24-bold'>
                        {t('tokenomics.text11')}
                        </div>
                        <div className='text-color02 mobile-body-12-regular
                                        tablet-body-24-regular
                                        desktop-body-16-regular text-left'>
                        {t('tokenomics.text12')} 
                        </div>
                    </div>
                </Content>
            </div>
            
        </div>
    );
}

export default Tokenomics;