import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-responsive-modal';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

function TokenNomicsModal({detailTokenomics,setDetailTokenomics}) {
    const { t } = useTranslation();

    const closeModal = () => setDetailTokenomics(false);
    return (
        <Modal open={detailTokenomics} onClose={() => setDetailTokenomics(false)} center classNames={{
            overlay : 'customOverlay',
            modal: 'custom_modal'
          }} showCloseIcon={false}>
           <div className='flex flex-col max-w-[19.5rem] w-full gap-[1.5rem] justify-center items-center
                          sm:max-w-[45rem] sm:gap-[1.88rem]
                          md:max-w-[57rem]'>
              <div className='self-stretch flex flex-row justify-end items-center focus:outline-none'>
                  <img src='/assets/tokenomics/modal/close.png' alt='' className='w-[3rem] h-[3rem] sm:w-[4rem] sm:h-[4rem] cursor-pointer'  onClick={closeModal} />
              </div>  

              <div className='w-full'>
              <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    allowTouchMove={false}
                    simulateTouch={false}
                    navigation={{
                        nextEl: '.modal-next-arrow',
                        prevEl: '.modal-prev-arrow',
                      }}
                    
                    >
                    <SwiperSlide>
                        <div className='flex flex-col justify-center items-center gap-[1.5rem] sm:gap-[1.88rem]'>
                            <div className='flex flex-row justify-center items-center w-[5.5rem] h-[5.5rem] sm:w-[10rem] sm:h-[10rem]'>
                                <img src='/assets/tokenomics/modal/titan.png' alt='' />
                            </div>
                            <div className='flex flex-col gap-[0.5rem]'>
                                <div className='flex flex-col gap-[0.75rem]'>
                                <div className='flex flex-row justify-center gap-[0.75rem] mobile-title-20 tablet-title-36 desktop-title-36-bold'>
                                    <div className='text-color04'>{t('modal_tokenomics.text1')}</div>
                                    <div className='text-white'>{t('modal_tokenomics.text2')}</div>
                                </div>
                                <div className='text-color02 mobile-body-18-regular tablet-body-18-regular desktop-body-18-regular text-center'>
                                {t('modal_tokenomics.text3')}
                                </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='flex flex-col justify-center items-center gap-[1.5rem] sm:gap-[1.88rem]'>
                            <div className='flex flex-row justify-center items-center w-[5.5rem] h-[5.5rem] sm:w-[10rem] sm:h-[10rem]'>
                                <img src='/assets/tokenomics/modal/titan.png'  alt='' />
                            </div>
                            <div className='flex flex-col gap-[0.5rem]'>
                                <div className='flex flex-col gap-[0.75rem]'>
                                    <div className='flex flex-row justify-center gap-[0.75rem] mobile-title-20 tablet-title-36 desktop-title-36-bold'>
                                        <div className='text-color04'>{t('modal_tokenomics.text4')}</div>
                                        <div className='text-white'>{t('modal_tokenomics.text5')}</div>
                                    </div>
                                    <div className='text-color02 mobile-body-18-regular tablet-body-18-regular desktop-body-18-regular text-center'>
                                    {t('modal_tokenomics.text6')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='flex flex-col justify-center items-center gap-[1.5rem] sm:gap-[1.88rem]'>
                            <div className='flex flex-row justify-center items-center w-[5.5rem] h-[5.5rem] sm:w-[10rem] sm:h-[10rem]'>
                                <img src='/assets/tokenomics/modal/titan.png'  alt='' />
                            </div>
                            <div className='flex flex-col gap-[0.5rem]'>
                                <div className='flex flex-col gap-[0.75rem]'>
                                    <div className='flex flex-row justify-center gap-[0.75rem] mobile-title-20 tablet-title-36 desktop-title-36-bold'>
                                        <div className='text-color04'>{t('modal_tokenomics.text7')}</div>
                                        <div className='text-white'>{t('modal_tokenomics.text8')}</div>
                                    </div>
                                    <div className='text-color02 mobile-body-18-regular tablet-body-18-regular desktop-body-18-regular text-center'>
                                    {t('modal_tokenomics.text9')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
            </Swiper>
              </div>
              <div className='flex flex-row justify-between self-stretch'>
                <div className='flex py-[1rem] px-[0.75rem] sm:p-[1.5rem] modal-prev-arrow focus:outline-none'>
                  <img src='/assets/tokenomics/modal/arrow_left.png' className='w-[3.5rem] h-[0.625rem] sm:w-[4.375rem] sm:h-[0.75rem]' alt='' />
                </div>
                <div className='flex py-[1rem] px-[0.75rem] modal-next-arrow focus:outline-none'>
                  <img src='/assets/tokenomics/modal/arrow_right.png' className='w-[3.5rem] h-[0.625rem] sm:w-[4.375rem] sm:h-[0.75rem]' alt='' />
                </div>
              </div>
           </div>
        </Modal>
    );
}

export default TokenNomicsModal;