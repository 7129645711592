import { useEffect, useState } from 'react';
import './App.css';
import Abstract from './components/Abstract';
import Footer from './components/Footer';
import Gnb from './components/Gnb';
import JoinUs from './components/JoinUs';
import Link from './components/Link';
import OurDApps from './components/OurDApps';
import OurVision from './components/OurVision';
import Press from './components/Press';
import TextMain from './components/TextMain';
import Tokenomics from './components/Tokenomics';
import TokenNomicsModal from './components/modal/TokenNomicsModal';
import Menu from './components/Menu';
import { useTranslation } from 'react-i18next';


const App = () => {
  const {i18n } = useTranslation();
  const [detailTokenomics, setDetailTokenomics] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [lang, setLang] = useState("en");

  // 브라우저 언어 접속 설정
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    const languageCode = browserLanguage.split('-')[0];
    if(languageCode === "ko"){
      setLang(languageCode);
      i18n.changeLanguage(languageCode);
    }
    else if(languageCode === "zh") {
      setLang("cn");
      i18n.changeLanguage("cn");
    }
    else {
      setLang("en");
      i18n.changeLanguage("en");
    }
  },[i18n])
  return (
    <div id='top'>
      {/* 완료 */}
      <Gnb setShowMenu={setShowMenu} lang={lang} setLang={setLang} />
      <Menu showMenu={showMenu} setShowMenu={setShowMenu} lang={lang} setLang={setLang} /> 
      <div className='h-full w-full'>
        <div className='relative overflow-hidden w-full'>
          <img src="/assets/background/main/main_img_left-back.png" className='main_img_left-back' alt='' />
          <img src="/assets/background/main/shape.png" className='shape' alt=''  />
          <video src='/assets/background/main/main_video_left.mov' autoPlay muted loop playsInline className='main_video_left' />
          <video src='/assets/background/main/main_video_right.mov' autoPlay muted loop  playsInline className='main_video_right' />
          <div className='main_gradation' />
          <TextMain />
        </div>
      </div>
      <Link />
      <div className='w-full h-full'>
        <div className='relative overflow-hidden w-full'>
          <img src="/assets/background/body/abstract-left.svg" className='abstract-left' alt='' />
          <img src="/assets/background/body/abstract-right.svg" className='abstract-right' alt='' />
          <img src="/assets/background/body/ourvision.png" className='ourvision' alt='' />
          <img src="/assets/background/body/ourdapps_back.png" className='ourdapps_back' alt='' />
          <img src="/assets/background/body/ourdapps_front.png" className='ourdapps_front' alt='' />
          <img src="/assets/background/body/press.png" className='press' alt='' />
          {/* <div className='bg_joinus> */}
          <img src="/assets/background/body/bg_joinus.svg" className='bg_joinus' alt='' />
          <div className='video_joinus' />
          <Abstract lang={lang} />
          <OurVision />
          <Tokenomics modalFn={setDetailTokenomics} />
          <OurDApps />
          <Press />
          <JoinUs />
          <Footer />
        </div>
      </div>
      <TokenNomicsModal detailTokenomics={detailTokenomics} setDetailTokenomics={setDetailTokenomics} />
    </div>
  );
}

export default App;
