import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();

    return (
        <div className='max-w-[1920px]mx-auto pt-[1.5rem] pb-[2.5rem] px-[1.5rem] flex flex-col gap-[0.5rem]
                        sm:py-[3.5rem] sm:px-[1.5rem] sm:gap-[1rem]
                        md:py-[6rem] gnb_padding md:gap-[2rem] relative z-[1] bg-[#0f0f0f]'>
            <div className='flex flex-row gap-[0.5rem] justify-center items-center
                            sm:gap-[1rem]
                            md:gap-[1.5rem]'>
                <img src='/assets/footer/medium.png' alt='' className='w-[1.25rem] h-[1.25rem]
                sm:w-[2.6875rem] sm:h-[2.6875rem]
                md:w-[3rem] md:h-[3rem] cursor-pointer' onClick={() => window.open("https://medium.com/@triplegoldglobal")} />
                <img src='/assets/footer/x.png' alt=''  className='w-[1.25rem] h-[1.25rem]
                sm:w-[2.6875rem] sm:h-[2.6875rem]
                md:w-[3rem] md:h-[3rem] cursor-pointer'  onClick={() => window.open("https://twitter.com/TGG_Official_CH")} />
                <img src='/assets/footer/telegram.png' alt='' className='w-[1.25rem] h-[1.25rem]
                sm:w-[2.6875rem] sm:h-[2.6875rem]
                md:w-[3rem] md:h-[3rem] cursor-pointer' onClick={() => window.open("https://t.me/TGG_Official")} />
            </div>
            <div className='text-center text-color02 mobile-body-10-medium
                            tablet-body-24-regular
                            desktop-body-18-regular'>
                {t('footer.text1')}

            </div>
        </div>
    );
}

export default Footer;