import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

function Gnb({setShowMenu,lang, setLang}) {
    const { t, i18n } = useTranslation();

    const onChangeLanguage = useCallback((type) => {
        i18n.changeLanguage(type);
        setLang(type);
    },[i18n, setLang])

    // default
    const getMenuText = useMemo(() => {
        if(lang === "en") return t('language.text1')
        else if(lang === "cn") return t('language.text2')
        else return t('language.text3')
    },[lang, t])
    return (
        <div className='px-[1.5rem]
                        md:pt-[1rem] md:px-[1rem] fixed top-0 left-0 right-0 z-[10] '>
            <div className='flex flex-row pl-[0.75rem] pr-[0.25rem] justify-between items-center rounded-[0.75rem] bg-[#1A1A1A]
                            sm:pl-[1.62rem] sm:pr-[0.5rem] sm:rounded-[1.25rem]
                            gnb_padding'>
                                <Link to='top' spy smooth>
                                    <div className='flex-none flex flex-row gap-[0.125rem] sm:gap-[0.26669rem] md:gap-[0.125rem] cursor-pointer py-[1rem]'>
                                        <div className='logo w-[6.875rem] sm:w-[14.6875rem] md:w-[11.875rem]' >
                                            <img src="/assets/gnb/logo.png" alt='' />
                                        </div>
                                    </div>
                                </Link>
                                <div className='hidden md:flex md:flex-row md:flex-1 md:gap-[1.25rem] md:justify-center md:items-center text-color01 desktop-body-16-medium'>
                                    <Link to='abstract' spy smooth>
                                        <div className='py-[2rem] px-0 cursor-pointer'>
                                        {t('menu.text1')}
                                        </div>
                                    </Link>
                                    <Link to='our_vision' spy smooth>
                                        <div className='py-[2rem] px-0 cursor-pointer'>
                                        {t('menu.text2')}
                                        </div>
                                    </Link>
                                    <Link to='tokenomics' spy smooth>
                                        <div className='py-[2rem] px-0 cursor-pointer'>
                                        {t('menu.text3')}
                                        </div>
                                    </Link>
                                    <Link to='our_dapps' spy smooth>
                                        <div className='py-[2rem] px-0 cursor-pointer'>
                                        {t('menu.text4')}
                                        </div>
                                    </Link>
                                    <Link to='press' spy smooth>
                                        <div className='py-[2rem] px-0 cursor-pointer'>
                                        {t('menu.text5')}
                                        </div>
                                    </Link>
                                    <Link to='join_us' spy smooth>
                                        <div className='py-[2rem] px-0 cursor-pointer'>
                                        {t('menu.text6')}
                                        </div>
                                    </Link>
                                </div>
                                {/* dropdown */}
                                <Menu>
                                    <MenuButton className='hidden md:flex md:flex-row md:desktop-body-16-medium md:text-color01 md:gap-[0.125rem]'>
                                        <img src="/assets/gnb/global.svg" alt='' />
                                        {getMenuText}  
                                        <img src="/assets/gnb/arrow_down.svg" alt='' />
                                    </MenuButton>
                                    <Transition
                                    enter="transition ease-out duration-75"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                    >
                                        <MenuItems
                                            anchor="bottom end"
                                            className="z-[10] w-52 origin-top-right rounded-xl border border-white/5 bg-black p-1 desktop-body-16-medium text-white [--anchor-gap:var(--spacing-1)] focus:outline-none"
                                        >
                                            <MenuItem>
                                            <button onClick={() => onChangeLanguage("en")} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                                            {t('language.text1')}
                                            </button>
                                            </MenuItem>
                                            <MenuItem>
                                            <button onClick={() => onChangeLanguage("cn")}  className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                                            {t('language.text2')}
                                            </button>
                                            </MenuItem>
                                            <MenuItem>
                                            <button onClick={() => onChangeLanguage("ko")}  className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                                            {t('language.text3')}
                                            </button>
                                            </MenuItem>
                                            {/* <MenuItem>
                                            <button onClick={() => onChangeLanguage("jp")}  className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                                            {t('language.text4')}
                                            </button>
                                            </MenuItem> */}
                                        </MenuItems>
                                    </Transition>
                                </Menu>
                                {/* mobile,tablet */}
                                <img src='/assets/gnb/icon_menu.svg' className='w-[2.75rem] h-[2.75rem]
                                sm:w-[5.875rem] sm:h-[5.875rem]
                                block md:hidden cursor-pointer' onClick={() => setShowMenu(true)}  alt=''/>
            </div>
            
        </div>
    );
}

export default Gnb;