import React from 'react';
import Content from './template/Content';

function Link() {
    return (
        <Content className='content max-w-[1920px] mx-auto flex justify-center items-center  bg-[#0D0D0D]
                        py-[1rem] gap-[4.25rem]
                        sm:py-[2rem] sm:gap-[8.5rem]
                        md:py-[1rem] relative  z-[1]'> 
                <div className='flex w-[2.75rem] h-[0.5625rem] items-center
                                sm:w-[5.875rem] sm:h-[1.1875rem] link_img_01
                                md:w-[11rem] md:h-[2.25rem] cursor-pointer' onClick={() => window.open("https://www.lbank.com/trade/titan_usdt")}/>
                <div className='flex w-[3.1875rem] h-[0.875rem] items-center link_img_02
                                sm:w-[6.8125rem] sm:h-[1.875rem]
                                md:w-[12.75rem] md:h-[3.5rem] cursor-pointer' onClick={() => window.open("https://www.xt.com/en/trade/titan_usdt")} />
                <div className='flex w-[1.875rem] h-[0.8125rem] items-center link_img_03
                                sm:w-[4rem] sm:h-[1.75rem]
                                    md:w-[7.5rem] md:h-[3.25rem] cursor-pointer' onClick={() => window.open("https://p2pb2b.com/trade/TITAN_USDT/")} />
        </Content>
    );
}

export default Link;