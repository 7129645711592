import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from './template/Content';

function Press() {
    const { t } = useTranslation();

    return (
        <div className='max-w-[1920px] mx-auto relative z-[1] flex flex-col py-[2.5rem] gap-[1.25rem]
                        sm:py-[4.5rem] sm:gap-[2rem]
                        md:py-[10.5rem] md:gap-[4rem]' id='press'>
            <Content className='content-bottom flex flex-row px-[1.5rem] items-center
                            gnb_padding'>
                <div className='flex-1 text-color04 mobile-title-32
                                tablet-title-64
                                desktop-title-68'>
                {t('press.text1')}
                </div>
            </Content>
            <Content className='content flex flex-row overflow-x-auto mx-[1.5rem]  gap-[1rem] 
                            sm:gap-[2rem]
                            md:ml-[17rem] md:gap-[1rem] scrollbar-hide'>
                {/* card 1 */}
                <div className='flex flex-col items-center rounded-[1.5rem] border border-[#3d3d3d] shadow-press-shadow
                                sm:rounded-[2.5rem] press_bg_img cursor-pointer' onClick={() => window.open("https://digitalchosun.dizzo.com/site/data/html_dir/2024/05/21/2024052180097.html")}>
                    <div className='w-[14.375rem] h-[9.625rem] press_img_01
                                    sm:w-[30.6875rem] sm:h-[20.5625rem]
                                    md:w-[28rem] md:h-[19.125rem]' />
                    <div className='flex flex-col p-[1.5rem] gap-[0.5rem] h-[10.25rem]
                                    sm:p-[3rem] sm:gap-[1rem] sm:h-[21.8125rem]
                                    md:p-[1.5rem] md:gap-[1rem] md:h-[17.5rem]'>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-row gap-[0.3rem] flex-1 items-center
                                            sm:gap-[0.75rem]'>
                                <img src='/assets/press/chosunbiz.png' alt='' className='w-[0.75rem] h-[0.75rem] sm:w-[2rem] sm:h-[2rem] md:w-[2.5rem] md:h-[2.5rem]' />
                                <div className='text-color04 mobile-body-14-semibold
                                                tablet-body-32-semibold
                                                desktop-body-24-bold'>
                                    {t('press.text2')}
                                </div>
                            </div>
                            
                        </div>
                        <div className='flex flex-row justify-end text-color02 mobile-body-12-regular tablet-body-24-regular desktop-body-18-regular'>
                            2024.05.21
                        </div>
                        <div className='flex-1 text-color01 mobile-body-12-regular tablet-body-24-regular desktop-body-20-regular'>
                            {t('press.text3')}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col items-center rounded-[1.5rem] border border-[#3d3d3d] shadow-press-shadow
                                sm:rounded-[2.5rem] press_bg_img cursor-pointer' onClick={() => window.open("https://www.hankyung.com/article/202401301250O")}>
                    <div className='w-[14.375rem] h-[9.625rem] press_img_02
                                    sm:w-[30.6875rem] sm:h-[20.5625rem]
                                    md:w-[28rem] md:h-[19.125rem]' />
                    <div className='flex flex-col p-[1.5rem] gap-[0.5rem] h-[10.25rem]
                                    sm:p-[3rem] sm:gap-[1rem] sm:h-[21.8125rem]
                                    md:p-[1.5rem] md:gap-[1rem] md:h-[17.5rem]'>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-row gap-[0.3rem] flex-1 items-center
                                            sm:gap-[0.75rem]'>
                                <img src='/assets/press/hankyoung.png' alt='' className='w-[0.75rem] h-[0.75rem] sm:w-[2rem] sm:h-[2rem] md:w-[2.5rem] md:h-[2.5rem]' />
                                <div className='text-color04 mobile-body-14-semibold
                                                tablet-body-32-semibold
                                                desktop-body-24-bold'>
                                    {t('press.text4')}
                                </div>
                            </div>
                            
                        </div>
                        <div className='flex flex-row justify-end text-color02 mobile-body-12-regular tablet-body-24-regular desktop-body-18-regular'>
                            2024.01.30
                            </div>
                        <div className='flex-1 text-color01 mobile-body-12-regular tablet-body-24-regular desktop-body-20-regular'>
                            {t('press.text5')}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col items-center rounded-[1.5rem] border border-[#3d3d3d] shadow-press-shadow
                                sm:rounded-[2.5rem] press_bg_img cursor-pointer' onClick={() => window.open("https://www.ajunews.com/view/20240202150212836")}>
                    <div className='w-[14.375rem] h-[9.625rem] press_img_03
                                    sm:w-[30.6875rem] sm:h-[20.5625rem]
                                    md:w-[28rem] md:h-[19.125rem]' />
                    <div className='flex flex-col p-[1.5rem] gap-[0.5rem] h-[10.25rem]
                                    sm:p-[3rem] sm:gap-[1rem] sm:h-[21.8125rem]
                                    md:p-[1.5rem] md:gap-[1rem] md:h-[17.5rem]'>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-row gap-[0.3rem] flex-1 items-center
                                            sm:gap-[0.75rem]'>
                                <img src='/assets/press/ajnews.png' alt='' className='w-[0.75rem] h-[0.75rem] sm:w-[2rem] sm:h-[2rem] md:w-[2.5rem] md:h-[2.5rem]' />
                                <div className='text-color04 mobile-body-14-semibold
                                                tablet-body-32-semibold
                                                desktop-body-24-bold'>
                                    {t('press.text6')}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-end text-color02 mobile-body-12-regular tablet-body-24-regular desktop-body-18-regular'>
                            2024.02.02
                        </div>
                        <div className='flex-1 text-color01 mobile-body-12-regular tablet-body-24-regular desktop-body-20-regular'>
                            {t('press.text7')}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col items-center rounded-[1.5rem] border border-[#3d3d3d] shadow-press-shadow
                                sm:rounded-[2.5rem] press_bg_img cursor-pointer' onClick={() => window.open("https://www.dailian.co.kr/news/view/1324649")}>
                    <div className='w-[14.375rem] h-[9.625rem] press_img_04
                                    sm:w-[30.6875rem] sm:h-[20.5625rem]
                                    md:w-[28rem] md:h-[19.125rem]' />
                    <div className='flex flex-col p-[1.5rem] gap-[0.5rem] h-[10.25rem]
                                    sm:p-[3rem] sm:gap-[1rem] sm:h-[21.8125rem]
                                    md:p-[1.5rem] md:gap-[1rem] md:h-[17.5rem]'>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-row gap-[0.3rem] flex-1 items-center
                                            sm:gap-[0.75rem]'>
                                <img src='/assets/press/dailian.png' alt='' className='w-[0.75rem] h-[0.75rem] sm:w-[2rem] sm:h-[2rem] md:w-[2.5rem] md:h-[2.5rem]' />
                                <div className='text-color04 mobile-body-14-semibold
                                                tablet-body-32-semibold
                                                desktop-body-24-bold'>
                                    {t('press.text8')}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-end text-color02 mobile-body-12-regular tablet-body-24-regular desktop-body-18-regular'>
                            2024.02.02
                        </div>
                        <div className='flex-1 text-color01 mobile-body-12-regular tablet-body-24-regular desktop-body-20-regular'>
                            {t('press.text9')}
                        </div>
                    </div>
                </div>
            </Content>
        </div>
    );
}

export default Press;