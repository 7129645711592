import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from './template/Content';

const TextMain = () => {
    const { t } = useTranslation();
    return (
        <div className=' mx-auto pt-[5rem] pb-[5.5rem] px-[1.5rem] flex flex-col gap-[1.5rem]
                        sm:pt-[10.67rem] sm:pb-[11.73rem] sm:px-[1.5rem] sm:gap-[3.2rem]
                        md:pt-[21.5rem] md:pb-[16.5rem] md:gap-[4.5rem] relative  z-[1]'>
            <div className='flex flex-col gap-[1rem]
                            sm:gap-[2rem]'>
                <div className='flex flex-col gap-[0.25rem] items-center
                                sm:gap-[0.5rem] 
                                md:gap-[0.75rem]'>
                    <Content className='content-bottom bg-color03  py-0  justify-center items-center text-color06 px-[0.125rem] gap-[0.125rem] rounded-[0.375rem] mobile-body-12-bold text-center
                                    sm:gap-[0.5rem] sm:px-[0.5rem] sm:rounded-[0.75rem] tablet-body-24-bold
                                    desktop-body-20-bold'>
                        {t('main_text.text1')}
                    </Content>
                    <Content className='content-bottom text-color01 text-center mobile-body-12-bold
                                    tablet-body-24-bold
                                    desktop-body-20-bold'>
                        {t('main_text.text2')}
                    </Content>
                </div>
                <Content className='content-bottom text-color04 text-center mobile-headline
                                tablet-headline
                                desktop-headline' html={t('main_text.text3')}>
                </Content>
            </div>
            <Content className='content-bottom text-color02 text-center mobile-body-12-regular
                                tablet-body-24-regular
                                desktop-body-20-regular' html={t('main_text.text4')}>
            </Content>
        </div>
    );
}

export default TextMain;