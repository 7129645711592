import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-scroll';
import { Menu as MN, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

function Menu({showMenu,setShowMenu,lang, setLang}) {
  const { t, i18n } = useTranslation();
  const onChangeLanguage = useCallback((type) => {
      i18n.changeLanguage(type);
      setLang(type);
  },[i18n, setLang])
  const getMenuText = useMemo(() => {
    if(lang === "en") return t('language.text1')
    else if(lang === "cn") return t('language.text2')
    else return t('language.text3')
  },[lang, t])
    return (
        <div className={`flex flex-col md:hidden fixed top-0 ${showMenu ? 'right-0' : '-right-full'} menu-list px-[1.5rem] z-[10]
                      gap-[2rem] pt-[0.75rem] sm:pt-0 w-full h-full transition-[right] duration-200 ease-in`}>
          <div className='flex-1 overflow-auto'>
            <div className='absolute right-0 flex flex-row justify-end'>
             
              <div className=' w-[2.75rem] h-[2.75rem]
                             sm:w-[5.86669rem] sm:h-[5.86669rem] cursor-pointer' onClick={() => setShowMenu(false)}>
                <img src='/assets/gnb/close.svg' alt=''  />
              </div>
            </div>
            <div className='flex flex-col py-[1.5rem] text-color01 mobile-title-20  tablet-body-32-semibold'>
              <Link to='abstract' spy smooth onClick={() => setShowMenu(false)} offset={-50} > 
                  <div className='py-[1rem] cursor-pointer'>
                  {t('menu.text1')}
                  </div>
              </Link>
              <Link to='our_vision' spy smooth onClick={() => setShowMenu(false)} offset={-80} >
                <div className='py-[1rem] cursor-pointer'>
                {t('menu.text2')}
                </div>
              </Link>
              <Link to='tokenomics' spy smooth onClick={() => setShowMenu(false)} offset={-50} >
                <div className='py-[1rem] cursor-pointer'>
                {t('menu.text3')}
                </div>
              </Link>
              <Link to='our_dapps' spy smooth onClick={() => setShowMenu(false)} offset={-50} >
                <div className='py-[1rem] cursor-pointer'>
                {t('menu.text4')}
                </div>
              </Link>
              <Link to='press' spy smooth onClick={() => setShowMenu(false)} offset={-50} >
                <div className='py-[1rem] cursor-pointer'>
                {t('menu.text5')}
                </div>
              </Link>
              <Link to='join_us' spy smooth onClick={() => setShowMenu(false)} offset={-50} >
                <div className='py-[1rem] cursor-pointer'>
                {t('menu.text6')}
                </div>
              </Link>
            </div>
            <div className='flex-1 bg-red-300' />
            <MN>
                <MenuButton className='flex px-[0.75rem] py-[0.7rem] gap-[0.25rem] items-center border-rounded
                                      sm:px-[1.5rem] sm:py-[1rem]'>
                  <div className='flex items-center gap-[0.125rem] sm:gap-[0.266669rem]'>
                    <div className='self-stretch flex items-center'>
                          <img src='/assets/gnb/global.svg' className='w-[1.25rem] h-[1.25rem] sm:w-[2rem] sm:h-[2rem]'  alt=''/>
                      </div>
                      <div className='flex flex-row justify-center items-center gap-[-0.25rem]'>
                          <div className='text-color01 mobile-title-2 tablet-body-32-semibold'>
                            {getMenuText}
                          </div>
                          <div className='self-stretch flex items-center'>
                            <img src='/assets/gnb/arrow_down.svg' className='w-[1.25rem] h-[1.25rem] sm:w-[2rem] sm:h-[2rem]'  alt=''/>
                          </div>
                      </div>
                  </div>
                  </MenuButton>
                <Transition
                  enter="transition ease-out duration-75"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                  >
                      <MenuItems
                          className="border-rounded z-[10] w-52  border-white/5 bg-black p-1 mobile-title-2 tablet-body-32-semibold text-white focus:outline-none"
                      >
                          <MenuItem>
                          <button onClick={() => onChangeLanguage("en")} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                          {t('language.text1')}
                          </button>
                          </MenuItem>
                          <MenuItem>
                          <button onClick={() => onChangeLanguage("cn")} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                          {t('language.text2')}
                          </button>
                          </MenuItem>
                          <MenuItem>
                          <button onClick={() => onChangeLanguage("ko")} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                          {t('language.text3')}
                          </button>
                          </MenuItem>
                      </MenuItems>
                  </Transition>
              </MN>
            <div className='absolute right-[6.6%] bottom-[2.5rem] text-white'>
                <img src='/assets/gnb/abstract-right.svg' className='w-[9.25rem] h-[8.875rem] sm:w-[17.5625rem] sm:h-[16.875rem]' alt='' />
            </div>
            <div className='absolute left-[29.4%] bottom-0 text-white'>
                <img src='/assets/gnb/abstract-left.svg' className='w-[5.0625rem] h-[4.8125rem] sm:w-[10.625rem] sm:h-[10.625rem]' alt='' />
            </div>
          </div>
      </div>
    );
}

export default Menu;