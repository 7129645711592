import React from 'react';
import { useTranslation } from 'react-i18next';
import Content from './template/Content';

function OurVision() {
    const { t } = useTranslation();

    return (
        <div className='max-w-[1920px] mx-auto flex py[2.5rem] px-[1.5rem] flex-col items-center gap-[1.25rem] shrink-0
                        sm:py-[4.5rem] sm:px-[1.5rem] sm:gap-[2rem] relative  z-[1]
                        md:py-[10.5rem] gnb_padding md:gap-[4rem]' id='our_vision'>
            {/* tit */}
            <div className='flex flex-col items-start gap-[0.5rem]
                            sm:gap-[1rem] md:gap-[2rem]'>
                <Content className='content-bottom text-color04 mobile-title-32 tablet-title-64 desktop-title-68'>
                    {t('our_vision.text1')}
                </Content>
                <Content className=' content-bottom text-color02 mobile-body-12-regular tablet-body-24-regular desktop-body-18-regular'  html={t('our_vision.text2') }  />
            </div>
            {/* grid image */}
            <div className='self-stretch grid grid-cols-2 gap-[1rem]
                            sm:grid-cols-3 text-white'>
                <Content className='content p-[1rem] flex justify-center items-center rounded-[1.5rem] border border-[#3d3d3d] our_vision_img_01
                                sm:col-span-2 sm:p-0 sm:rounded-[2.5rem]
                                md:p-[2.625rem]'>
                    <div className='flex flex-1 text-color01 self-stretch items-end justify-center mobile-body-12-regular 
                                    sm:justify-start tablet-body-24-regular sm:pl-[1.06rem] sm:pb-[0.37rem]
                                    md:p-0 desktop-body-24-bold'>
                    {t('our_vision.text3')}
                    </div>
                </Content>
                <Content className='content aspect-1 p-[1rem] flex justify-center items-center rounded-[1.5rem] border border-[#3d3d3d] our_vision_img_02
                                sm:p-0 sm:rounded-[2.5rem]
                                md:p-[2.625rem]'>
                    <div className='flex flex-1 text-color01 self-stretch items-end justify-center mobile-body-12-regular 
                                    sm:justify-start tablet-body-24-regular sm:pl-[1.06rem] sm:pb-[0.37rem]
                                    md:p-0 desktop-body-24-bold'>
                    {t('our_vision.text4')}
                    </div>
                </Content>
                <Content className='content aspect-1 p-[1rem] flex justify-center items-center rounded-[1.5rem] border border-[#3d3d3d] our_vision_img_03
                                sm:p-0 sm:rounded-[2.5rem]
                                md:p-[2.625rem]'>
                    <div className='flex flex-1 text-color01 self-stretch items-end justify-center mobile-body-12-regular 
                                    sm:justify-start tablet-body-24-regular sm:pl-[1.06rem] sm:pb-[0.37rem]
                                    md:p-0 desktop-body-24-bold'>
                    {t('our_vision.text5')}
                    </div>
                </Content>
                <Content className='content p-[1rem] flex justify-center items-center rounded-[1.5rem] our_vision_img_04
                                sm:col-span-2 sm:p-0 sm:rounded-[2.5rem]
                                md:p-[2.625rem]'>
                    <div className='flex flex-1 text-color01 self-stretch items-end justify-center mobile-body-12-regular 
                                    sm:justify-start tablet-body-24-regular sm:pl-[1.06rem] sm:pb-[0.37rem]
                                    md:p-0 desktop-body-24-bold'>
                    {t('our_vision.text6')}
                    </div>
                </Content>
                <Content className='content p-[1rem] flex justify-center items-center rounded-[1.5rem] our_vision_img_05
                                sm:col-span-2 sm:p-0 sm:rounded-[2.5rem]
                                md:p-[2.625rem]'>
                    <div className='flex flex-1 text-color01 self-stretch items-end justify-center mobile-body-12-regular 
                                    sm:justify-start tablet-body-24-regular sm:pl-[1.06rem] sm:pb-[0.37rem]
                                    md:p-0 desktop-body-24-bold'>
                    {t('our_vision.text7')}
                    </div>
                </Content>
                <Content className='content aspect-1 p-[1rem] flex justify-center items-center rounded-[1.5rem] our_vision_img_06
                                sm:p-0 sm:rounded-[2.5rem]
                                md:p-[2.625rem]'>
                    <div className='flex flex-1 text-color01 self-stretch items-end justify-center mobile-body-12-regular 
                                    sm:justify-start tablet-body-24-regular sm:pl-[1.06rem] sm:pb-[0.37rem]
                                    md:p-0 desktop-body-24-bold'>
                    {t('our_vision.text8')}
                    </div>
                </Content>
            </div>
        </div>
    );
}

export default OurVision;