import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Content from './template/Content';
import axios from '../lib/axios';

function JoinUs() {
    const { t } = useTranslation();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onSendContact = async() => {
        if(!isLoading) {
            setIsLoading(true);
            let now = new Date();
            axios.post("/contact", {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    regDate : now.toLocaleString()
            })
            .then((response) => {
                setIsLoading(false);
                if(response.status === 200){
                    // 성공
                    if(response.data.result) {
                        alert(t('join_us.text8'));
                        setFirstName('');
                        setLastName('');
                        setEmail('');
                    }
                    // 실패
                    else {
                        alert('값을 모두 입력하세요.');
                        return;
                    }
                }
            }).catch((error) => {
                setIsLoading(false);
                console.log(error.response)
            });
        }
    }
    return (
        <div className='max-w-[1920px] mx-auto py-[2.5rem] px-[1.5rem] flex flex-col gap-[1.25rem]
                        sm:py-[4.5rem] sm:px-[1.5rem] sm:gap-[2rem]
                        md:py-[10.5rem] gnb_padding md:gap-[4rem]
                        self-stretch relative z-[1]' id='join_us'>
            <Content className='content-bottom text-center text-color01 mobile-body-14-semibold
                            tablet-body-32-semibold
                            desktop-title-60
                            self-stretch'>
                {t('join_us.text1')}
            </Content>
            <Content className='content-bottom py-[2.5rem] px-[2rem] flex flex-col self-stretch justify-center items-center gap-[1.25rem] joinus_frame w-[19.5rem]
                            sm:py-[4.5rem] sm:px-[5.75rem] sm:gap-[2rem] sm:w-[45rem]
                            md:py-[6rem] md:px-[7.5rem] md:gap-[4rem] md:w-[43rem]
                            mx-auto'>
                <div className='flex flex-col gap-[0.25rem]
                                sm:gap-[0.5rem]
                                md:gap-[1rem]
                                self-stretch'>
                    <div className='text-center text-color04 mobile-body-14-semibold
                                    tablet-body-32-semibold
                                    desktop-body-24-bold
                                    self-stretch'>
                    {t('join_us.text2')}
                    </div>
                    <div className='text-center text-color02 mobile-body-12-regular
                                    tablet-body-24-regular
                                    desktop-body-18-regular
                                    self-stretch' dangerouslySetInnerHTML={{ __html: t('join_us.text3') }} >
                    </div>
                </div>
                {/* input */}
                <div className='flex flex-col gap-[0.5rem]
                                sm:gap-[1rem]
                                md:gap-[1.5rem]
                                self-stretch'>
                    <div className='flex flex-col gap-[0.125rem]
                                    sm:gap-[0.26669rem]
                                    md:gap-[0.375rem]
                                    self-stretch'>  
                        <div className='text-color02 mobile-body-12-regular
                                        tablet-body-24-regular
                                        desktop-body-16-regular
                                        self-stretch'>
                        {t('join_us.text4')}
                        </div>
                        <div className='gap-[0.25rem] 
                                        sm:gap-[0.53331rem]
                                        md:gap-[0.5rem]
                                        self-stretch'>
                            <input type="text" className="block w-full rounded-[0.75rem] border border-[#3d3d3d] bg-color06
                                        h-[2.5rem] p-[0.5rem]
                                        sm:h-[5.3125rem] sm:p-[1.06669rem] sm:rounded-[1.25rem]
                                        md:h-[4rem] md:p-[1rem] text-color01 mobile-body-12-regular
                                        tablet-body-24-regular
                                        desktop-body-16-regular focus:outline-none" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                    </div>
                    <div className='flex flex-col gap-[0.125rem]
                                    sm:gap-[0.26669rem]
                                    md:gap-[0.375rem]
                                    self-stretch'>  
                        <div className='text-color02 mobile-body-12-regular
                                        tablet-body-24-regular
                                        desktop-body-16-regular
                                        self-stretch'>
                        {t('join_us.text5')}
                        </div>
                        <div className='gap-[0.25rem] 
                                        sm:gap-[0.53331rem]
                                        md:gap-[0.5rem]
                                        self-stretch'>
                            <input type="text" className="block w-full rounded-[0.75rem] border border-[#3d3d3d] bg-color06
                                        h-[2.5rem] p-[0.5rem]
                                        sm:h-[5.3125rem] sm:p-[1.06669rem] sm:rounded-[1.25rem]
                                        md:h-[4rem] md:p-[1rem] text-color01 mobile-body-12-regular
                                        tablet-body-24-regular
                                        desktop-body-16-regular focus:outline-none" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                    </div>
                    <div className='flex flex-col gap-[0.125rem]
                                    sm:gap-[0.26669rem]
                                    md:gap-[0.375rem]
                                    self-stretch'>  
                        <div className='text-color02 mobile-body-12-regular
                                        tablet-body-24-regular
                                        desktop-body-16-regular
                                        self-stretch'>
                        {t('join_us.text6')}
                        </div>
                        <div className='gap-[0.25rem] 
                                        sm:gap-[0.53331rem]
                                        md:gap-[0.5rem]
                                        self-stretch'>
                            <input type="email" className="block w-full rounded-[0.75rem] border border-[#3d3d3d] bg-color06
                                        h-[2.5rem] p-[0.5rem]
                                        sm:h-[5.3125rem] sm:p-[1.06669rem] sm:rounded-[1.25rem]
                                        md:h-[4rem] md:p-[1rem] text-color01 focus:outline-none" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                </div>
                {/* button */}
                <div className='p-[0.75rem] gap-[0.25rem] rounded-[0.75rem] bg-color03 text-color06 text-center mobile-body-14-semibold
                                sm:p-[1.6rem] sm:gap-[0.53331rem] sm:rounded-[1.25rem] tablet-body-32-semibold
                                md:py-[1.25rem] md:px-[1.5rem] md:gap-[0.5rem] desktop-body-24-bold
                                self-stretch cursor-pointer relative' onClick={onSendContact}>
                    {t('join_us.text7')}
                    {isLoading && <div className='absolute top-0 left-0 w-full h-full bg-black opacity-80  flex items-center justify-center
                                    rounded-[0.75rem] sm:rounded-[1.25rem] '>
                        {/* <Oval visible
                            height="40"
                            width="40"
                            color="#0065ac"
                            ariaLabel="oval-loading"
                        /> */}
                    </div>}
                </div>
            </Content>
        </div>
    );
}

export default JoinUs;