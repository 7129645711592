import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Content from './template/Content';


function Abstract({lang}) {
    const { t } = useTranslation();
    const openYellowPaper = useCallback(() => {
        let type = "KOR";
        if(lang === "en")       type = "ENG"
        else if(lang === "cn")  type="CHI";

        window.open(`http://www.docu.triplegoldglobal.org/Titan_Yellowpaper_${type}_v0.1.pdf`);
    },[lang]);
    return (
        <div className='max-w-[1920px] mx-auto flex py-[2.5rem] px-[1.5rem] flex-col
                        sm:py-[4.5rem] sm:px-[1.5rem]
                        md:py-[10.5rem] gnb_padding relative  z-[1]' id='abstract'>
            {/* desktop - abstract*/}
            <div className='hidden md:flex flex-col items-center gap-[-6.25rem]'>
                {/* top */}
                <div className='flex justify-between items-start self-stretch'>
                    {/* txt */}
                    <Content className='content-left w-[40%] flex flex-col items-start gap-[2rem]'>
                        <div className='text-color04 desktop-title-68'>
                            {t('abstract.text1')}
                        </div>
                        <div className='text-color02 desktop-body-18-regular'>
                            {t('abstract.text2')}
                        </div>
                        {/* yellow paper */}
                        <div className='flex items-center gap-[0.625rem] cursor-pointer' onClick={openYellowPaper}>
                            <div className='text-color03 desktop-body-24-bold'>
                                {t('abstract.text3')}
                            </div>
                            <img src='/assets/abstract/arrow_right.png' alt='' className='w-[4.375rem] h-[0.75rem]' />
                        </div>
                    </Content>
                    {/* card */}
                    <Content className='content-right w-[41%] flex p-[3.5rem] flex-col items-center gap-[1.75rem] abstract_bg_img'>
                        {/* top */}
                        <div className='flex flex-row self-stretch'>
                            {/* tit */}
                            <div className='pt-[0.75rem] items-start gap-[0.625rem] flex-1 self-stretch text-01 desktop-title-36
                                            text-color01 abstract_title'>
                                {t('abstract.text4')}
                            </div>
                            <div className='flex-none abstract_img_size'>
                                <img src='/assets/abstract/economic_stability.png' alt='' />
                            </div>
                        </div>
                        <div className='text-color02 desktop-body-16-regular' dangerouslySetInnerHTML={{ __html: t('abstract.text5') }} >
                        </div>
                    </Content>
                </div>
                {/* center */}
                <div className='flex pl-[7.25rem] flex-col items-start shrink-0 self-stretch abstract_margin_top'>
                    {/* card */}
                    <Content className='content-left w-[41%] flex p-[3.5rem] flex-col items-center gap-[1.75rem] abstract_bg_img'>
                        {/* top */}
                        <div className='flex flex-row self-stretch'>
                            {/* tit */}
                            <div className='pt-[0.75rem] items-start gap-[0.625rem] flex-1 self-stretch text-01 desktop-title-36
                                            text-color01 abstract_title'>
                                {t('abstract.text6')}
                            </div>
                            <div className='flex-none abstract_img_size'>
                                <img src='/assets/abstract/operational_transparency.png' alt='' />
                            </div>
                        </div>
                        <div className='text-color02 desktop-body-16-regular' dangerouslySetInnerHTML={{ __html: t('abstract.text7') }} >
                        </div>
                    </Content>
                </div>
                {/* bottom */}
                <Content className='content-right flex flex-col items-end shrink-0 self-stretch abstract_margin_top'>
                    {/* card */}
                    <div className='w-[41%] flex p-[3.5rem] flex-col items-center gap-[1.75rem] abstract_bg_img'>
                        {/* top */}
                        <div className='flex flex-row self-stretch'>
                            {/* tit */}
                            <div className='pt-[0.75rem] items-start gap-[0.625rem] flex-1 self-stretch text-01 desktop-title-36
                                            text-color01 abstract_title'>
                                {t('abstract.text8')}
                            </div>
                            <div className='flex-none abstract_img_size'>
                                <img src='/assets/abstract/scalability.png' alt='' />
                            </div>
                        </div>
                        <div className='text-color02 desktop-body-16-regular' dangerouslySetInnerHTML={{ __html: t('abstract.text9') }} >
                        </div>
                    </div>
                </Content>
            </div>
            {/* tablet/mobile */}
            <div className='md:hidden'>
                {/* abstract */}
                <div className='flex flex-col gap-[0.5rem]
                                sm:gap-[1rem]'>
                    <Content className='content-bottom text-color04 mobile-title-32 tablet-title-64'>
                        {t('abstract.text1')}
                    </Content>
                    <Content className='content-bottom text-color02 mobile-body-12-regular tablet-body-24-regular'>
                        {t('abstract.text2')}
                    </Content>
                    <Content className='content-bottom flex flex-row items-center gap-[0.25rem]'>
                        <div className='text-color03 mobile-body-14-semibold tablet-body-32-semibold'>
                            {t('abstract.text3')}
                        </div>
                        <div className='w-[1.75rem] h-[0.3125rem] sm:w-[3.75rem] sm:h-[0.6875rem]'>
                            <img src="/assets/abstract/arrow_right.png" alt='' />
                        </div>
                    </Content>
                </div>
                {/* mobile 간격 */}
                <div className='h-[1.5rem]
                                sm:h-[2.5rem]' />
                <div className='flex flex-col gap-[1rem]
                                sm:gap-[2rem]'>
                    <Content className='content-bottom abstract_bg_img p-[2.5rem] flex flex-col gap-[1rem]
                                    sm:px-[5.75rem] sm:py-[4.5rem]'>
                        <div className='flex flex-row'>
                            <div className='flex-1 text-color01 mobile-title-20 pt-[0.75rem]
                                            tablet-title-40 sm:pt-[1.5rem]'>
                                {t('abstract.text4')}
                            </div>
                            <div className='w-[5rem] h-[5rem]
                                            sm:w-[10.5rem] sm:h-[10.5rem]'>
                                <img src='/assets/abstract/economic_stability.png' alt='' />
                            </div>
                        </div>
                        <div className='text-color02 mobile-body-12-regular tablet-body-24-regular' dangerouslySetInnerHTML={{ __html: t('abstract.text5') }} >
                        </div>
                    </Content>
                    <Content className='content-bottom abstract_bg_img p-[2.5rem] flex flex-col gap-[1rem]
                                    sm:px-[5.75rem] sm:py-[4.5rem]'>
                        <div className='flex flex-row'>
                            <div className='flex-1 text-color01 mobile-title-20 pt-[0.75rem]
                                            tablet-title-40 sm:pt-[1.5rem]'>
                            {t('abstract.text6')}
                            </div>
                            <div className='w-[5rem] h-[5rem]
                                            sm:w-[10.5rem] sm:h-[10.5rem]'>
                                <img src='/assets/abstract/operational_transparency.png' alt='' />
                            </div>
                        </div>
                        <div className='text-color02 mobile-body-12-regular tablet-body-24-regular' dangerouslySetInnerHTML={{ __html: t('abstract.text7') }} > 
                        </div>
                    </Content>
                    <Content className='content-bottom abstract_bg_img p-[2.5rem] flex flex-col gap-[1rem]
                                    sm:px-[5.75rem] sm:py-[4.5rem]'>
                        <div className='flex flex-row'>
                            <div className='flex-1 text-color01 mobile-title-20 pt-[0.75rem]
                                            tablet-title-40 sm:pt-[1.5rem]'>
                            {t('abstract.text8')}
                            </div>
                            <div className='w-[5rem] h-[5rem]
                                            sm:w-[10.5rem] sm:h-[10.5rem]'>
                                <img src='/assets/abstract/scalability.png' alt='' />
                            </div>
                        </div>
                        <div className='text-color02 mobile-body-12-regular tablet-body-24-regular' dangerouslySetInnerHTML={{ __html: t('abstract.text9') }} > 
                        </div>
                    </Content>
                </div>
            </div>
        </div>
    );
}

export default Abstract;