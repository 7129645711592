import React from 'react';
import { useTranslation } from 'react-i18next';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Content from './template/Content';

function OurDApps() {
    const { t } = useTranslation();

    return (
        <div className='max-w-[1920px] mx-auto py-[2.5rem] px-[1.5rem] flex flex-col gap-[1.25rem]
                        sm:py-[4.5rem] sm:px-[1.5rem] sm:gap-[2rem]
                        md:py-[10.5rem] gnb_padding md:gap-[4rem] relative  z-[1]' id='our_dapps'>
            <Content className='text-color04 mobile-title-32
                            tablet-title-64
                            desktop-title-68'>
                {t('our_dapps.text1')}
            </Content>
            <Content className='content'>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    navigation={{
                        nextEl: '.custom-next-arrow',
                        prevEl: '.custom-prev-arrow',
                      }}
                    pagination={{
                        el: '.custom-pagination',
                        clickable: true,
                        bulletClass : 'pagination',
                        bulletActiveClass: 'active',
                        renderBullet: (index, className) => {
                            return `<div class='${className}'></div>`;
                        },
                    }}
                    >
                    <SwiperSlide>
                        <div className='flex flex-col gap-[1.25rem] sm:gap-[2rem] md:gap-[4rem]'>
                            <div className='flex-1 flex justify-center items-center w-full h-[11.22381rem] sm:h-[25.90119rem] md:h-[56.25rem]'>
                                <img src='/assets/our_dapps/content_01.png' alt='' />
                            </div>
                            <div className='p-[0.5rem] cloud_mining_btn text-color02 text-center mobile-body-14-semibold
                                            sm:p-[1rem] tablet-body-32-semibold
                                            md:p-[1.5rem] desktop-title-36'>
                                {t('our_dapps.text2')}
                            </div>
                        </div>
                        
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='flex flex-col gap-[1.25rem] sm:gap-[2rem] md:gap-[4rem]'>
                            <div className='flex-1 flex justify-center items-center w-full h-[11.22381rem] sm:h-[25.90119rem] md:h-[56.25rem]'>
                                <img src='/assets/our_dapps/content_02.png'  alt=''/>
                            </div>
                            <div className='p-[0.5rem] cloud_mining_btn text-color02 text-center mobile-body-14-semibold
                                            sm:p-[1rem] tablet-body-32-semibold
                                            md:p-[1.5rem] desktop-title-36'>
                                {t('our_dapps.text3')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='flex flex-col gap-[1.25rem] sm:gap-[2rem] md:gap-[4rem]'>
                            <div className='flex-1 flex justify-center items-center w-full h-[11.22381rem] sm:h-[25.90119rem] md:h-[56.25rem]'>
                                <img src='/assets/our_dapps/content_03.png'  alt=''/>
                            </div>
                            <div className='p-[0.5rem] cloud_mining_btn text-color02 text-center mobile-body-14-semibold
                                            sm:p-[1rem] tablet-body-32-semibold
                                            md:p-[1.5rem] desktop-title-36'>
                                {t('our_dapps.text4')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='flex flex-col gap-[1.25rem] sm:gap-[2rem] md:gap-[4rem]'>
                            <div className='flex-1 flex justify-center items-center w-full h-[11.22381rem] sm:h-[25.90119rem] md:h-[56.25rem]'>
                                <img src='/assets/our_dapps/content_04.png'  alt=''/>
                            </div>
                            <div className='p-[0.5rem] cloud_mining_btn text-color02 text-center mobile-body-14-semibold
                                            sm:p-[1rem] tablet-body-32-semibold
                                            md:p-[1.5rem] desktop-title-36'>
                                {t('our_dapps.text5')}
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Content>
            <Content className='content flex flex-col gap-[0.75rem] sm:gap-[2rem] md:gap-[4rem]'>
                <div className='flex flex-row justify-between items-center'>
                    <div className='py-[1rem] px-[0.5rem]
                                    sm:p-[1.25rem]
                                    md:p-[1.5rem] custom-prev-arrow'>
                        <img src='/assets/our_dapps/prev.png' className='w-[2.1875rem] h-[0.375rem]
                            sm:w-[3.25rem] sm:h-[0.5625rem]
                            md:w-[4.375rem] md:h-[0.75rem]'  alt=''/>
                    </div>
                    <div className='custom-pagination'></div>
                    <div className='py-[1rem] px-[0.5rem]
                                    sm:p-[1.25rem]
                                    md:p-[1.5rem] custom-next-arrow'>
                        <img src='/assets/our_dapps/next.png' className='w-[2.1875rem] h-[0.375rem]
                            sm:w-[3.25rem] sm:h-[0.5625rem]
                            md:w-[4.375rem] md:h-[0.75rem]' alt='' />
                    </div>
                    
                </div>
            </Content>
        </div>
    );
}

export default OurDApps;